import { parseTime } from './baling'
import BigNumber from 'bignumber.js'
import moment from 'moment'
// 设置周一为一周的开始
moment.updateLocale('en', {
  week: {
    dow: 1 // 周一为一周的开始
  }
})

// 页面 url 转组件名 /goods/index => GoodsIndex
export const routerPathToName = (routerPath) => routerPath.split('/').filter(x => Boolean(x)).map(x => x.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())).join('');

/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
  if (cellValue == null || cellValue == '') return ''
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return (
    year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  )
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

export function makeMap(str, expectsLowerCase) {
  const map = Object.create(null)
  const list = str.split(',')
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true
  }
  return expectsLowerCase ? val => map[val.toLowerCase()] : val => map[val]
}

export const exportDefault = 'export default '

export const beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

// 下划转驼峰
export function camelCase(str) {
  return str.replace(/_[a-z]/g, str1 => str1.substr(-1).toUpperCase())
}

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
}

//实时时间
export function itmes(date) {
  // 格式化时间为 YYYY-MM-DD HH:MM:SS
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var seconds = date.getSeconds() // 判断是不是小于10 返回01 02 03
  function check(num) {
    if (num < 10) {
      // 如果数字小于10,前边拼接个0
      return '0' + num
    } else {
      return num
    }
  }
  let timeArry = {}
  let timeText = `${check(year)}年${check(month)}月${check(day)}日 ${check(
    hours
  )}:${check(minutes)}:${check(seconds)}`
  let timeTextFormatOne = `${check(year)}-${check(month)}-${check(day)} ${check(
    hours
  )}:${check(minutes)}:${check(seconds)}`
  let nowDay = date.getDay()
  let weeks = new Array(
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六'
  )
  let week = weeks[nowDay] // let state = ``; // // 判断当前时间段 // if (hours >= 0 && hours <= 10) { //   state = `早上`; // } else if (hours > 10 && hours <= 14) { //   state = `中午`; // } else if (hours > 14 && hours <= 18) { //   state = `下午`; // } else if (hours > 18 && hours <= 24) { //   state = `晚上`; // }
  timeArry.timeText = timeText
  timeArry.timeTextFormatOne = timeTextFormatOne
  timeArry.week = week // timeArry.state = state;
  return timeArry // 时间展示 // return ` //         ${check(year)}年 //         ${check(month)}月 //         ${check(day)}日 //         ${check(hours)} : //         ${check(minutes)} : //         ${check(seconds)}`;
}

//时间戳转日期时间
export function timestampToTime(timestamp) {
  // 时间戳为10位需*1000，时间戳为13位不需乘1000
  var date = new Date(timestamp * 1000)
  var year = date.getFullYear()
  var month =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return (
    year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  )
}

//日期YYMMDD + N天 后的日期
export function getDateAddDay(now_date, n) {
  let new_date = new Date(now_date)
  new_date = +new_date + 1000 * 60 * 60 * 24 * n
  new_date = new Date(new_date)
  //格式化
  let month =
    new_date.getMonth() + 1 < 10
      ? '0' + (new_date.getMonth() + 1)
      : new_date.getMonth() + 1
  let date =
    new_date.getDate() + 1 < 10 ? '0' + new_date.getDate() : new_date.getDate()
  return new_date.getFullYear() + '-' + month + '-' + date
}
// 判断是不是数组
export function isArray(data) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(data) === '[object Array]'
  }
  return Array.isArray(data)
}

// 判断是不是对象
export function isObject(data) {
  return Object.prototype.toString.call(data) === '[object Object]'
}

// 判断是不是数字
export function isNumber(data) {
  return (
    Object.prototype.toString.call(data) === '[object Number]' && !isNaN(data)
  )
}

// 判断对象含有某个属性
export function objectHas(obj, key) {
  return isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key)
}

// 深拷贝
export function deepCopy(obj) {
  if (isArray(obj) || isObject(obj)) {
    const cloneObj = new obj.constructor()
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      const item = deepCopy(obj[key])
      if (isArray(obj)) {
        cloneObj.push(item)
      } else {
        cloneObj[key] = item
      }
    }
    return cloneObj
  }
  return obj
}

// 下拉加载
export function directives(className, type) {
  let selector = 'el-select-dropdown'
  if (type === 'input') {
    selector = 'el-autocomplete-suggestion'
  }
  return {
    bind(el, binding) {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(
        `.${selector}.${className} .el-scrollbar__wrap`
      )
      SELECTWRAP_DOM.addEventListener('scroll', function () {
        // eslint-disable-next-line no-invalid-this
        const condition =
          this.scrollHeight - this.scrollTop <= this.clientHeight // 距离底部20px
        if (condition) {
          binding.value() // 调用handleLoadMore
        }
      })
    }
  }
}

// 输入文字转义
export function strEscape(str) {
  try {
    const txt = JSON.stringify(str)
    return txt.slice(1, txt.length - 1)
  } catch (error) {
    return str
  }
}

// 判断是否有内容,防止忽视0  space:true ''算内容
export function haveContent(str, space = false) {
  return Boolean(
    str || str === 0 || str === false || (space ? str === '' : false)
  )
}

export const getUid = (length = 32, times = false) => {
  let timestamp = ''
  if (times) {
    timestamp = String(Math.round(new Date()))
  }
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-'.split('') //分割字符。
  let result = '' //返回的结果。
  const len = length - timestamp.length
  const getRandom = () => {
    let res = characters[Math.round(Math.random() * characters.length)]
    if (!res) {
      res = getRandom()
    }
    return res
  }
  while (result.length < len) result = result + getRandom()
  return result + timestamp
}


export const getUidNum = (length = 32, times = false) => {
  let timestamp = ''
  if (times) {
    timestamp = String(Math.round(new Date())).slice(7)
  }
  const characters = '0123456789'.split('') //分割字符。
  let result = '' //返回的结果。
  const len = length - timestamp.length
  const getRandom = () => {
    let res = characters[Math.round(Math.random() * characters.length)]
    if (!res) {
      res = getRandom()
    }
    return res
  }
  while (result.length < len) result = result + getRandom()
  return timestamp + result
}

// 浮点计算
export const fcount = (arr = [], way, { pow = 2, type = '' } = {}) => {
  let result = 0
  const c = { '+': 'plus', '-': 'minus', '*': 'times', '/': 'div' }
  arr.forEach((num, i) => {
    if (i === 0) {
      result = Number(num || 0)
    } else {
      result = new BigNumber(result)[c[way]](Number(num || 0)).toNumber()
    }
  })
  if (type === 'floor') {
    // 向下取整
    result = Math.floor(result * Math.pow(10, pow)) / Math.pow(10, pow)
  } else if (type === 'ceil') {
    // 向上取整
    result = Math.ceil(result * Math.pow(10, pow)) / Math.pow(10, pow)
  } else if (type === 'round') {
    // 四舍五入
    result = Math.round(result * Math.pow(10, pow)) / Math.pow(10, pow)
  }else{
    //直接截取
    if(result.toString().indexOf('.') > -1){
      let splitA = result.toString().split('.');
      if(splitA[1] && splitA[1].length > Number(pow)){
        result = truncate(result,pow);
      }
    }
  }
  return result
}

//截取小数而不进行四舍五入
export const truncate = (num, precision) => {
  const factor = Math.pow(10, precision);
  return Math.trunc(num * factor) / factor;
}

export const getImgInfo = src =>
  new Promise((resolve, reject) => {
    // 创建实例对象
    const img = new Image()
    // 图片地址
    img.src = src
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })

export const getENVIR = () => {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') !== -1
  const isInApp = /(^|;\s)app\//.test(ua)
  if (isWeixin) {
    if (window.__wxjs_environment === 'miniprogram') {
      return 'wxapp'
    } else {
      return 'wxh5'
    }
  } else {
    if (!isInApp) {
      return 'browser'
    } else {
      return 'app'
    }
  }
}

//限制输入框只能输入数字
/* 
value 值
forms 赋值的字段或对象
field 被赋值的名称
isRow 是否行内
decimals 小数位数 默认8位 (为0时，不支持小数只支持整数)
isMinus 是否支持负数 默认不支持
 */
export function limitInputlength(
  value,
  forms,
  field = '',
  isRow = false,
  decimals = 8,
  isMinus = false,
  maxNum = 9999999
) {
  let checkPlan = '' + value

  const t = checkPlan.charAt(0) //得到第一个字符是否为负号
  let decimalsStr = '\\d'.repeat(decimals)
  decimalsStr = '^(-)*(\\d+)\\.(' + decimalsStr + ').*$'

  checkPlan = checkPlan
    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/^\./g, '') // 保证第一个为数字而不是.
  // console.log(decimals, 'decimals')
  if (decimals == 0) {
    //去除.
    checkPlan = checkPlan.replace(/\./g, '')
  } else {
    // 只保留第一个. 清除多余的
    checkPlan = checkPlan
      .replace(/\.{2,}/g, '.')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
  }

  if (checkPlan.indexOf('.') < 0 && checkPlan !== '') {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    checkPlan = parseFloat(checkPlan) + ''
  } else if (checkPlan.indexOf('.') >= 0) {
    checkPlan = checkPlan.replace(new RegExp(decimalsStr), '$1$2.$3') // 限制小数
  }

  if (Number(checkPlan) > maxNum) {
    checkPlan = maxNum
  }
  //如果第一位是负号，则允许添加
  if (isMinus && t == '-') {
    checkPlan = '-' + checkPlan
  }
  if (!isRow) {
    const numArray = forms.split('.') // 将字符串拆分为数组
    let target = this
    for (let i = 0; i < numArray.length - 1; i++) {
      target = target[numArray[i]]
    }
    target[numArray[numArray.length - 1]] = checkPlan
  } else {
    //行内
    // const lastForms = forms.split('.').pop()
    // const lastIndex = forms.lastIndexOf('.')
    // const resultForms = forms.slice(0, lastIndex)
    // console.log('resultForms', resultForms)
    // console.log('lastForms', lastForms)
    this.$set(forms, field, checkPlan)
  }

  return checkPlan
}

//截取字符串 开始-结束
export function substringBetween(str, open, close) {
  if (str != null && open != null && close != null) {
    let start = str.indexOf(open)
    if (start != -1) {
      let end = str.indexOf(close, start + open.length)
      if (end != -1) {
        return str.substring(start + open.length, end)
      }
    }

    return null
  } else {
    return null
  }
}

/* 
printDateType : 样式类型  (1:2018年07月12日  2:2018/07/12  3:yyyy年dd月tt日 HH时mm分ss秒  4:2018/07/12 10:24:56)
formatType : 默认类型 '/'  可传不同符号分割 
addYear: 当前年份(增加/减少)N年
addMonth: 当前月份(增加/减少)N月
addDay: 当前天数(增加/减少)N天
 */
export function getFormattedDate(
  printDateType,
  formatType = '/',
  addYear = 0,
  addMonth = 0,
  addDay = 0
) {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + addDay)
  const year = currentDate.getFullYear() + addYear
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')
  let billDate = ''
  switch (printDateType) {
    case 1:
      return `${year}年${month}月${day}日`

    case 2:
      if (formatType) {
        billDate = `${year}${formatType}${month}${formatType}${day}`
      } else {
        billDate = `${year}${month}${day}`
      }
      return billDate
    case 3:
      return `${year}年${month}月${day}日 ${hours}时${minutes}分${seconds}秒`

    case 4:
      if (formatType) {
        billDate = `${year}${formatType}${month}${formatType}${day} ${hours}:${minutes}:${seconds}`
      } else {
        billDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
      }
      return billDate

    case 5:
      billDate = `${hours}:${minutes}:${seconds}`
      return billDate

    case 6:
      billDate = `${year}${month}${day}${hours}${minutes}${seconds}`
      return billDate
    default:
      return currentDate.toLocaleDateString()
  }
}


/* 
printDateType : 样式类型  (1:2018年07月12日  2:2018/07/12  3:yyyy年dd月tt日 HH时mm分ss秒  4:2018/07/12 10:24:56)
formatType : 默认类型 '/'  可传不同符号分割 
addYear: 当前年份(增加/减少)N年
addMonth: 当前月份(增加/减少)N月
addDay: 当前天数(增加/减少)N天
 */
export function getFormattedDateFun(
    printDateType,
    formatType = '/',
    addYear = 0,
    addMonth = 0,
    addDay = 0,
    addHours = 0,
    addMinutes = 0
  ) {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + addDay)
    if(addMinutes)  currentDate.setMinutes(currentDate.getMinutes() + addMinutes);
    const year = currentDate.getFullYear() + addYear
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')
    const seconds = String(currentDate.getSeconds()).padStart(2, '0')
    let billDate = ''
    switch (printDateType) {
      case 1:
        return `${year}年${month}月${day}日`
  
      case 2:
        if (formatType) {
          billDate = `${year}${formatType}${month}${formatType}${day}`
        } else {
          billDate = `${year}${month}${day}`
        }
        return billDate
      case 3:
        return `${year}年${month}月${day}日 ${hours}时${minutes}分${seconds}秒`
  
      case 4:
        if (formatType) {
          billDate = `${year}${formatType}${month}${formatType}${day} ${hours}:${minutes}:${seconds}`
        } else {
          billDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
        }
        return billDate
  
      case 5:
        billDate = `${hours}:${minutes}:${seconds}`
        return billDate
  
      case 6:
        billDate = `${year}${month}${day}${hours}${minutes}${seconds}`
        return billDate
      default:
        return currentDate.toLocaleDateString()
    }
  }

/**
 *  获取时间范围 daterange
 * @param {String} type 范围类型
 * @param {String} format 日期类型  daterange 年月日  datetimerange 年月日时分秒
 * @param {Boolean} HMSRang 是否时分秒进行 整天区间取值 默认 true
 * @returns {Array} 日期范围数组
 */
export function getTimes({
  type = 'day',
  format = 'daterange',
  HMSRang = true
} = {}) {
  let start = ''
  let end = ''
  const formats = {
    daterange: 'YYYY-MM-DD',
    daterangeStart: 'YYYY-MM-DD',
    daterangeEnd: 'YYYY-MM-DD',
    datetimerange: 'YYYY-MM-DD hh:mm:ss',
    datetimerangeStart: 'YYYY-MM-DD 00:00:00',
    datetimerangeEnd: 'YYYY-MM-DD 23:59:59'
  }
  const sFormat = formats[`${format}${HMSRang ? 'Start' : ''}`]
  const eFormat = formats[`${format}${HMSRang ? 'End' : ''}`]
  switch (type) {
    case "latelyThreeAndDay": // 最近近三天：今天、昨天、前天
      start = moment().subtract(2, "days").format(sFormat);
      end = moment().format(eFormat)
      break;
    case "nextThreeAndDay": // 最近近三天：今天、明天、后天
      start = moment().format(sFormat)
      end = moment().add(2, 'days').format(eFormat);
      break;
    case "BAThreedays": // 最近七天
      start = moment().subtract(3, "days").format(sFormat);
      end = moment().add(3, 'days').format(eFormat);
      break;
    case "tomorrow": // 明天
      start = moment().add(1, 'days').format(sFormat);
      end = moment().add(1, 'days').format(eFormat);
      break;

    case 'latelySevenDay': // 最近七天
      start = moment().subtract(6, 'days').format(sFormat)
      end = moment().format(eFormat)
      break
    case 'today': // 今天
    case 'day': // 今天
      start = moment().format(sFormat)
      end = moment().format(eFormat)
      break
    case 'yesterday': // 昨天
      start = moment().subtract(1, 'days').format(sFormat)
      end = moment().subtract(1, 'days').format(eFormat)
      break
    case 'thisWeek': // 本周
    case 'week': // 本周
      start = moment().startOf('week').format(sFormat)
      end = moment().endOf('week').format(eFormat)
      break
    case 'lastWeek': // 上周
      start = moment().subtract(7, 'days').startOf('week').format(sFormat)
      end = moment().subtract(7, 'days').endOf('week').format(eFormat)
      break
    case 'thisMonth': // 本月
    case 'month': // 本月
      start = moment().startOf('month').format(sFormat)
      end = moment().endOf('month').format(eFormat)
      break
    case 'lastMonth': // 上月
      start = moment(moment().add(-1, 'M')).startOf('month').format(sFormat)
      end = moment(moment().add(-1, 'M')).endOf('month').format(eFormat)
      break
    case 'year': // 本年
      start = moment().startOf('year').format(sFormat)
      end = moment().endOf('year').format(eFormat)
      break
    case 'quarter': // 本季
      start = moment().startOf('quarters').format(sFormat)
      end = moment().endOf('quarters').format(eFormat)
      break
    case 'lastQuarter': // 上季
      start = moment(moment().add(-1, 'Q')).startOf('quarters').format(sFormat)
      end = moment(moment().add(-1, 'Q')).endOf('quarters').format(eFormat)
      break
    default:
      break
  }
  return start && end ? [start, end] : ''
}
export function getTimesIsPicker(type, dateTimeType = 'datetimerange') {
  let obj = {
    'daterange':'YYYY-MM-DD',
    'datetimerange':'YYYY-MM-DD 00:00:00',
  }
  let obj2 = {
    'daterange':'YYYY-MM-DD',
    'datetimerange':'YYYY-MM-DD 23:59:59',
  }
  let sFormat = obj[dateTimeType]
  let eFormat = obj2[dateTimeType]
  type = type ? type : [moment().format(sFormat), moment().format(eFormat)]

  if (
    type[0] == moment().format(sFormat) &&
    type[1] == moment().format(eFormat)
  ) {
    return 'today'
  } else if (
    type[0] == moment().subtract(1, 'days').format(sFormat) &&
    type[1] == moment().subtract(1, 'days').format(eFormat)
  ) {
    return 'yesterday'
  } else if (
    type[0] == moment().startOf('week').format(sFormat) &&
    type[1] == moment().endOf('week').format(eFormat)
  ) {
    return 'thisWeek'
  } else if (
    type[0] == moment().subtract(7, 'days').startOf('week').format(sFormat) &&
    type[1] == moment().subtract(7, 'days').endOf('week').format(eFormat)
  ) {
    return 'lastWeek'
  } else if (
    type[0] == moment().startOf('month').format(sFormat) &&
    type[1] == moment().endOf('month').format(eFormat)
  ) {
    return 'thisMonth'
  } else if (
    type[0] == moment(moment().add(-1, 'M')).startOf('month').format(sFormat) &&
    type[1] == moment(moment().add(-1, 'M')).endOf('month').format(eFormat)
  ) {
    return 'lastMonth'
  } else {
    return ''
  }
}

export function getMinPay(data, type = 'buy', yearsData = []) {
  //type=buy 新增  type=renew 续费
  //服务产品包年付费方式请求入参payModelItems
  let priceArr = data.payModelItems.map(x => {
    //产品包年包月模式  多长时间
    const item = yearsData.find(y => y.dictValue === x.payModelType)
    //门店价格
    let shopsPrice = 0
    let usersPrice = 0
    let possPrice = 0
    let productPrice = 0
    if (x.isManageShops) {
      //门店
      if (x.initializeShops) {
        //初始门店数和初始门店数单价有值才进来
        shopsPrice = fcount(
          [x.initializeShops, x.initializeShopsPrice],
          '*'
        )
      } else {
        //服务产品门店数价格回参  拿到购满数最小的
        const minElement = x.saasProductShopsPrices.reduce(
          (min, current) => {
            if (current.shops < min.shops) {
              return current
            } else {
              return min
            }
          }
        )
        shopsPrice = minElement.shopsPrice
      }
    }
    if (x.isManageUsers) {
      //用户
      if (x.initializeUsers) {
        //初始用户数和初始用户数单价有值才进来
        usersPrice = fcount(
          [x.initializeUsers, x.initializeUsersPrice],
          '*'
        )
      } else {
        //服务产品用户数价格回参  拿到购满数最小的
        const minElement = x.saasProductUsersPrices.reduce(
          (min, current) => {
            if (current.users < min.users) {
              return current
            } else {
              return min
            }
          }
        )
        usersPrice = minElement.usersPrice
      }
    }
    if (x.isManagePoss) {
      //站点
      if (x.initializePoss) {
        //初始站点数和初始站点数单价有值才进来
        possPrice = fcount([x.initializePoss, x.initializePossPrice], '*')
      } else {
        //服务产品站点数价格回参  拿到购满数最小的
        const minElement = x.saasProductPossPrices.reduce(
          (min, current) => {
            if (current.poss < min.poss) {
              return current
            } else {
              return min
            }
          }
        )
        possPrice = minElement.possPrice
      }
    }
    //最优惠价格   默认产品价格+门店用户站点的价格
    if (type == 'buy') {
      productPrice = x.productPrice
    }
    let payModelPrice = fcount(
      [shopsPrice, usersPrice, possPrice, productPrice],
      '+'
    )
    // console.log('item', payModelPrice, data)
    // 获取最小价格
    return { payModelPrice, label: item.dictLabel }
  })
  // 取一个最低价显示
  const minProduct = priceArr.reduce((min, current) => {
    if (current.payModelPrice < min.payModelPrice) {
      return current
    } else {
      return min
    }
  })
  return {
    pay: minProduct?.payModelPrice || '0.00',
    text: minProduct?.label || '年'
  }
}

export function concealStr(value, num = 4) {
  let newVale = String(value)
  switch (num) {
    case 4:
      newVale = newVale.split("")
      newVale.splice(Math.floor((newVale.length - num) / 2), num, '****')
      return newVale.join('')
    case 1:
      newVale = newVale.split("")
      newVale.splice(num, num, '*')
      return newVale.join('')
    default:
      break
  }
}

// 数组对象的深拷贝，JSON.parse(JSON.stringify(data))也支持数组对象的深拷贝，但是如果数组对象中含有方法会丢失
export const arrayObjectCopy = (obj) => {
  if (typeof obj !== 'object') {
    return obj
  }
  var res = Array.isArray(obj) ? [] : {};
  for (let i in obj) {
    res[i] = arrayObjectCopy(obj[i]);
  }
  return res
}


//对象合并，排除指定字段    excludeFields是[]
export const mergeExcept = (target, source, excludeFields) => {
  return {
    ...target,
    ...Object.fromEntries(
      Object.entries(source)
        .filter(([key]) => !excludeFields.includes(key))
    )
  };
};